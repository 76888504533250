import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Components.css";
import Card from "react-bootstrap/Card";
import { Col, Row, Image } from "react-bootstrap";
// import Avatar from "../Assest/logo/logo-noa.png";
export default function Recommends() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1500 },
      items: 4,
      slidesToSlide: 2,
    },

    desktop: {
      breakpoint: { max: 1500, min: 950 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 950, min: 550 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <div className="body2" style={{ marginBottom: "10%" }}>
      <Carousel
        className="carousel-rec"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        containerClass="carousel-container"
        autoPlay={true}
        rtl={true}
        showDots={true}
        focusOnSelect={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        autoPlaySpeed={4000}
        infinite={true}
        arrows={false}
        responsive={responsive}
      >
        {itemData.map((item, index) => (
          <div className="gallery-page div-card-rec">
            <Card>
              <Card.Body>
                <Row>
                  {/* <Col xs={6} md={4}>
                  <Image
                    src={Avatar} // Replace this with the direct link to your image
                    roundedCircle
                    width={40}
                  />
                  <p> נובמבר 2020</p>
                </Col>{" "} */}
                  <Col>
                    <Card.Text>{item.text}</Card.Text>
                    <Card.Text>
                      <b>{item.title}</b>
                    </Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

const itemData = [
  {
    text: "״תודה על הטיפול אתמול, כבר יש שינוי העור נראה יפה יותר ופחות קמטים. נהנתי מהטיפולומהחברה, אני שלך❣️״ ",
    title: "חני. ג מהוד השרון",
  },
  {
    text: "״העור נראה מושלם!!! יש לך ידי זהב״",
    title: "ליאור.ג מהוד השרון",
  },
  {
    text: "״העור נראה מהמם הסנטר נקי מבליטות של שומן תת עורי ושחורים והאף נקי, ממש טירוף נועה, תודה!!!❤️״ ",
    title: "שובל מ. מהוד השרון",
  },
  {
    text: "״אהובה שלי איזה כיף היה, לא היה לי ספק שאת מספר אחת והוכחת את זה ביג טיים, הפנים זוהרות ונעימות, היה מושלם!״ ",
    title: " רויטל.ק מבית ים",
  },
  {
    text: "״וואי המוצרים הם הכי נעימים וקלילים בעולם, מחכה לבוקר ולערב רק לשים אותם ולהריח. את הכי מהממת שיש!! באמת העולם היה צריך שתבחרי לעסוק בזה״",
    title: "שחף. ג מהוד השרון",
  },
  {
    text: "״היה ממש כיף!!! תודה נועית את תמיד כזאת מקצועית, תודה רבה💗💗״",
    title: "תמר.ד מתל אביב",
  },

  {
    text: "״ בוקר טוב, ואו תקשיבי אני חייבת להגיד לך שלא יודעת מה עשית לי אתמול בפנים אבל זהפשוט קסם!!! הן זוהרות מהרגיל ויש ממש לחות״",
    title: "סער ר. מהוד השרון",
  },
  {
    text: "״תודה העור נראה נקי כמו תמיד אין עלייך״",
    title: " תומר. א מכפר סבא",
  },
];
