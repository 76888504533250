import Button from "@mui/material/Button";
import React, { useContext } from "react";
import { Store } from "./Store";
function AddCart(props) {
  const { product } = props;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const addToCartHandler = async (item) => {
    console.log(item);
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;

    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...item, quantity },
    });
  };

  return (
    <Button
      style={{ color: "black", borderColor: "black" }}
      variant="outlined"
      onClick={() => addToCartHandler(product)}
    >
      הוספה
    </Button>
  );
}

export default AddCart;
