import React from "react";
import Banner from "../Components/Header/Banner";
import "./pages.css";
import { Button } from "react-bootstrap";

function About() {
  return (
    <div className="about">
      <Banner
        url={
          "url('https://i.imagesup.co/images2/9a88fedfae896747a3ead20d2d8c2346d90566a9.png')"
        }
      ></Banner>

      <div className="body">
        <div className="title-about">MY STORY</div>
        <div className="sub-title">נועה טלאל</div>
        <div className="text">
          <p>
            היי אני נועה טל-אל נעים להכיר. מאז שאני זוכרת את עצמי אני חיה ונושמת
            את עולם הקוסמטיקה.
          </p>
          <p>
            למדתי אצל חווה זינגבויים במשך שנה וחצי, ולאחר מכן עשיתי עוד המון
            קורסים והשתלמויות בתחום. עולם הקוסמטיקה מרגש ומעניין אותי, ואני תמיד
            רוצה ללמוד ולהביא את הדברים הכי חדשים.
          </p>
          <p>
            אני אוהבת להרגיש מטופחת ועוד יותר אוהבת לגרום ללקוחות שלי להרגיש
            בשיא שלהם. <br></br>כל לקוח/ה שנכנס אליי לקליניקה הוא עולם ומלואו.
          </p>
          <p>
            מזמינה אתכם לקליניקה שלי בהוד השרון למגוון טיפולים מקצועיים, עם
            מכשור מתקדם ותכשירים של החברות המובילות.
          </p>
        </div>
        <Button className="contact-btn" variant="outline-dark" href="/Contact">
          צור קשר
        </Button>
      </div>
    </div>
  );
}

export default About;
