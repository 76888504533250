import React from "react";
import "./pages.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";
import CarouselProduct from "../Components/Carousel";
import Banner from "../Components/Header/Banner";
import { Button } from "react-bootstrap";
import Images from "../Components/Images";
import Recommends from "../Components/Recommends";

function Home() {
  return (
    <div className="home">
      {/* {modalBox ? <ModalBox close={close} /> : <div></div>} */}
      <Banner
        url={
          "url(https://i.imagesup.co/images2/2232e54901434a0ee42ff786231cc238fe8aa42c.png)"
        }
        // url={
        //   "url(https://i.imagesup.co/images2/4d329271b61f0e5ad2f5ca116e2e8c60d868ac9d.png)"
        // }
      ></Banner>
      <h3 className="des-top"></h3>
      <div>
        <div className="body-carosel">
          <CarouselProduct></CarouselProduct>
        </div>
      </div>
      <Row className="card-about">
        <Col style={{ paddingRight: "0%", paddingLeft: "0%" }}>
          <img
            className="image-about"
            alt="img-about"
            src={
              "https://i.imagesup.co/images2/6b3d2231c6563b3de5f3a4409f2be3299f45d74a.jpg"
            }
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Col>
        <Col className="text-about">
          <h3 className="title">MY STORY</h3>
          <div style={{ margin: "3%", color: "#494949" }}>
            <p>
              היי אני נועה טל-אל נעים להכיר. מאז שאני זוכרת את עצמי אני חיה
              ונושמת את עולם הקוסמטיקה.
            </p>
            <p>
              למדתי אצל חווה זינגבויים במשך שנה וחצי, ולאחר מכן עשיתי עוד המון
              קורסים והשתלמויות בתחום. עולם הקוסמטיקה מרגש ומעניין אותי, ואני
              תמיד רוצה ללמוד ולהביא את הדברים הכי חדשים.
            </p>
            <p>
              אני אוהבת להרגיש מטופחת ועוד יותר אוהבת לגרום ללקוחות שלי להרגיש
              בשיא שלהם. <br></br>כל לקוח/ה שנכנס אליי לקליניקה הוא עולם ומלואו.
            </p>
            <p>
              מזמינה אתכם לקליניקה שלי בהוד השרון למגוון טיפולים מקצועיים, עם
              מכשור מתקדם ותכשירים של החברות המובילות.
            </p>
            <div className="read-more">
              <Button variant="outline-dark" href="/Contact">
                צור קשר
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <div style={{ marginBottom: "10%" }}>
        <Row className="card-service">
          <Col style={{ paddingRight: "0%", paddingLeft: "0%" }}>
            <img
              className="image-service"
              alt="img"
              src="https://i.imagesup.co/images2/73839eddc60854000dcd4069c8dcbef69269edb0.jpg"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Col>
          <Col className="text-service">
            <h3 className="title">TYPES OF GLOW</h3>
            <p style={{ margin: "3%", color: "#494949" }}>
              <p>
                <b>כל טיפול מתחיל באבחון מדוייק של העור, סוג העור והמצב שלו.</b>
              </p>
              <p style={{ textAlignLast: "center" }}>
                <span
                  style={{
                    textShadow: "2px 2px 8px #908888",
                  }}
                >
                  טיפול קלאסי -{" "}
                </span>
                משלב בתוכו ניקוי עמוק של העור, פילינגים, לחויות, מסכות ועיסויי
                עור הפנים.
              </p>
              <p style={{ textAlignLast: "center" }}>
                <span style={{ textShadow: "2px 2px 8px #908888" }}>
                  טיפול אקנה -{" "}
                </span>
                לאחר אבחון העור, והבנה ממה יוצאים הפצעונים והמצב הדלקתי בעור,
                נטפל בשורשהבעיה. טיפול זה משלב פילינגים, מסכות וניקוי עמוק של
                העור.
              </p>
              <p style={{ textAlignLast: "center" }}>
                <span style={{ textShadow: "2px 2px 8px #908888" }}>
                  טיפול פיגמנטציה -{" "}
                </span>
                לאחר שאיבחנו את סוג הפיגמנטציה, נשלב בטיפול מגוון פילינגים,
                חומצות, מסכות ומכשורמתקדם כדי להבהיר את כתמי העור.
              </p>
              <p style={{ textAlignLast: "center" }}>
                <span style={{ textShadow: "2px 2px 8px #908888" }}>
                  טיפול אלקטרופורציה -{" "}
                </span>
                טיפול של טכנולוגיה מתקדמת לשיקום העור. בעזרת מכשור מתאים נטפל
                במיצוק העור, בקמטוטי הבעה, ריכוך השפתיים, הדגשת קו לסת והבהרת
                העור. מותאם באופן אישי לכל מטופל והצורך של העור.
              </p>
              <p style={{ textAlignLast: "center" }}>
                <span style={{ textShadow: "2px 2px 8px #908888" }}>
                  טיפול מזותרפיה -{" "}
                </span>
                פציעה מבוקרת של עור הפנים הוא טיפול אנטי אייג'ינג אשר יטשטש את
                הצלקות והקמטיםשבעור הפנים. יעיל גם להבהרת כתמי עור.
              </p>
              <p style={{ textAlignLast: "center" }}>
                <span style={{ textShadow: "2px 2px 8px #908888" }}>
                  טיפול החדרת לחויות -{" "}
                </span>
                טיפול פינוק שמשלב בעיקר לחויות ועיסויים. טיפול שגורם לעור
                להיראות חי, זוהר ובשיא שלו.
              </p>
            </p>
            {/* <div className="read-more-service">
              <Button variant="outline-dark" href="/Contact">
                צור קשר
              </Button>
            </div> */}
          </Col>
        </Row>
      </div>
      <h1 className="title">customers recommend</h1>
      <Recommends></Recommends>
      <h3 className="title">FOLLOW US</h3>
      <Images></Images>
    </div>
  );
}

export default Home;
