import React from "react";
import ImageListItem from "@mui/material/ImageListItem";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Components.css";
export default function Images() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1500 },
      items: 5,
      slidesToSlide: 2,
    },

    desktop: {
      breakpoint: { max: 1500, min: 950 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 950, min: 550 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <Carousel
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-20-px"
      containerClass="carousel-container"
      autoPlay={true}
      rtl={true}
      focusOnSelect={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      autoPlaySpeed={2500}
      infinite={true}
      responsive={responsive}
    >
      {itemData.map((item, index) => (
        <a
          key={index}
          href="https://instagram.com/noa_talel?igshid=MzRlODBiNWFlZA=="
          className="link-insta"
          target="_blank"
          rel="noreferrer"
        >
          <ImageListItem key={item.img}>
            <img
              className="images-look"
              src={`${item.img}?w=824&h=824&fit=crop&auto=format`}
              srcSet={`${item.img}?w=824&h=824&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                height: 450,
                margin: 0,
              }}
            />
          </ImageListItem>
        </a>
      ))}
    </Carousel>
  );
}

const itemData = [
  {
    img: "https://i.imagesup.co/images2/02851ee24c3d7a8725a20e7a7a0b504a15ac1eeb.png",
    title: "1",
  },

  {
    img: "https://i.imagesup.co/images2/6b52e146611de74d04bdafe01e879467d25635c3.jpg",
    title: "2",
  },
  {
    img: "https://i.imagesup.co/images2/e59ee830784672195cc61b392afa55519df914dd.jpg",
    title: "3",
  },

  {
    img: "https://i.imagesup.co/images2/ac7074c679a89c33a105df84622b31a08a1e17f9.jpg",
    title: "4",
  },

  {
    img: "https://i.imagesup.co/images2/f6f372da50d28f84b2d9d9da09069f2efd7dbb62.jpg",
    title: "5",
  },

  {
    img: "https://i.imagesup.co/images2/6fdf8a7c916f49c39455b06dc109bc2f39548995.jpg",
    title: "6",
  },

  {
    img: "https://i.imagesup.co/images2/c756412d184c989bd79d040c05c42123a03cc4fc.jpg",
    title: "8",
  },

  {
    img: "https://i.imagesup.co/images2/6193ae6f8b876123541bad47ea8818fafb6c6000.jpg",
    title: "9/",
  },
  {
    img: "https://i.imagesup.co/images2/c1889bb8d92f42c1c86f2703318413955bd8048a.jpg",
    title: "10/",
  },
  {
    img: "https://i.imagesup.co/images2/23603951d1c89dca432a94df9278d4d2705f5f85.jpg",
    title: "11/",
  },
  {
    img: "https://i.imagesup.co/images2/6281f5be51efc853abe8da7b7432fad6383ea0d0.jpg",
    title: "12/",
  },
  {
    img: "https://i.imagesup.co/images2/aa9692d5a3de7854492741324e52e9fdb7d91ce1.jpg",
    title: "13/",
  },
  {
    img: "https://i.imagesup.co/images2/8479a6b5fa915223d1a5d9fb37dfb6ba0dcdccfa.jpg",
    title: "14/",
  },
  {
    img: "https://i.imagesup.co/images2/e4c14a3d5163db497d02f86e2297cf0b62e37dc3.jpg",
    title: "15",
  },
  {
    img: "https://i.imagesup.co/images2/f90fde0370d6ab392078a387183c7661e18be34a.png",
    title: "16",
  },
];
