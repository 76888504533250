import React, { useRef, useState } from "react";
import "./pages.css";
import Form from "react-bootstrap/Form";
import { Col, Row, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";

function Contact() {
  const form = useRef();
  const [Send, setSend] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);
    emailjs
      .sendForm(
        "service_2mbye4l",
        "template_inkcory",
        form.current,
        "3nh_vmEtIxbIRJhK2"
      )
      .then(
        (result) => {
          setSend(
            <Alert key={"success"} variant={"success"}>
              "ההודעה נשלחה בהצלחה, ניצור איתך קשר בהקדם."
            </Alert>
          );
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="contact-page">
      <h1 className="title">Contact</h1>
      <Row style={{ marginRight: "5%" }}>
        <Col sm={1} md={8}>
          <div>
            <h3>אשמח לעמוד לשירותך בדרכים הבאות</h3>
            <text> צריכה עזרה? אני כאן בשבילך</text>
            {Send}
            <Form className="form-contact" ref={form} onSubmit={sendEmail}>
              <Form.Control
                type="hidden"
                name="type_message"
                value="יצירת קשר"
              />

              <Row className="row-contact">
                <Col>
                  <Form.Control
                    inputMode="text"
                    type="text"
                    placeholder="שם מלא"
                    name="user_name"
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    inputMode="tel"
                    name="user_phone"
                    placeholder=" טלפון"
                  />
                </Col>
              </Row>
              <Row className="row-contact">
                <Col>
                  <Form.Control
                    inputMode="email"
                    placeholder="דואר"
                    type="email"
                    name="user_email"
                  />
                </Col>
              </Row>
              <Row className="row-contact">
                <Col>
                  <Form.Control
                    as="textarea"
                    name="message"
                    rows={8}
                    placeholder="תוכן ההודעה"
                  />
                </Col>
              </Row>
              <Button type="submit" value="Send" variant="outline-dark">
                שלח
              </Button>
            </Form>
          </div>
        </Col>
        <Col className="details-contact">
          <h3>שירות לקוחות</h3>
          <Row> ראשון עד חמישי: 8:00-21:00</Row>
          <Row> שישי: 8:00-14:00</Row>
          <Row>טל: 0506578979</Row>
          <Row>דואר: noaanoaa98@gmail.com</Row>
          <Row className="map-contact">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3377.45913898642!2d34.9051909193757!3d32.16489144780456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d38319203cb13%3A0x78eb1b87da01101e!2z15DXpNeo15nXnSDXp9em15nXqCA4LCDXlNeV15Mg15TXqdeo15XXnw!5e0!3m2!1siw!2sil!4v1700732335838!5m2!1siw!2sil"
              width="400"
              height="300"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              alt="Google Map"
            ></iframe>
          </Row>
          {/* <Row className="follow-text">עקבו אחרינו </Row>
          <SocialMedia></SocialMedia> */}
        </Col>
      </Row>
    </div>
  );
}

export default Contact;
