import React from "react";
import NavbarMenu from "./NavbarMenu";
import MessageNavbar from "./MessageNavbar";
import "./Header.css";

let message = [
  "Keep your face glowing",
  "ברוכים הבאים לאתר של נועה טל-אל",
  "10% הנחה על קנייה ראשונה באתר ",
];
function Header() {
  return (
    <div>
      <div className="navbar-message">
        <MessageNavbar messages={message}></MessageNavbar>
        <NavbarMenu></NavbarMenu>
      </div>
    </div>
  );
}

export default Header;
