import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";
import "./Components.css";
import logo from ".././Assest/logo/logo-noa.png";
import SocialMedia from "./SocialMedia";
export default function Footer() {
  return (
    <div className="body-footer" style={{ marginTop: "5px" }}>
      <div className="container-fluid text-center text-md-left">
        <Row
          style={{ paddingTop: "50px", paddingBottom: "50px" }}
          className="row-noa justify-content-center"
        >
          <Col xs={4} md={4} className="col-image-footer">
            <div className="image-footer">
              <img className="logo-footer" alt="logo" src={logo}></img>
            </div>
          </Col>
          <Col md={3} className="details-contact-footer">
            <p className="title-footer">נועה טל-אל</p>
            <p className="details-contact-footer">ראשון עד חמישי: 8:00-21:00</p>
            <p className="details-contact-footer">שישי: 8:00-14:00</p>

            <p className="details-contact-footer">
              כתובת: אפריים קציר , הוד השרון
            </p>
            <p className="details-contact-footer">טל: 0506578979</p>
            <p className="details-contact-footer">דואר: noaanoaa98@gmail.com</p>
          </Col>
          <Col className="follow" md={4}>
            <h3 className="title-footer">FOLLOW US</h3>
            <SocialMedia></SocialMedia>
          </Col>
        </Row>
        <Row>
          <div style={{ background: "#464343", color: "white", padding: "1%" }}>
            {/* <hr style={{ borderTop: "1px solid black", margin: "10px 0" }} /> */}
            <p style={{ fontSize: "16px" }}> © כל הזכויות שמורות לנועה טל-אל</p>
            {/* <hr style={{ borderTop: "1px solid ", margin: "10px 0" }} /> */}

            <p style={{ fontSize: "16px" }}>
              אתר זה נבנה ע"י{" "}
              <a
                href="https://amithalfon.com"
                target="_blank"
                style={{
                  color: "white",
                  fontWeight: 500, // Adjust the numeric value accordingly
                  fontStyle: "italic",
                  marginRight: "2px",
                }}
              >
                עמית חלפון
              </a>
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
}
