import React from "react";
import {
  faTiktok,
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Components.css";

function SocialMedia() {
  return (
    <div className="social">
      <a
        href="https://api.whatsapp.com/message/HCIHIGRHH5J3B1?autoload=1&app_absent=0"
        className="whatsapp social social-item"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon color="black" icon={faWhatsapp} size="2x" />
      </a>
      <a
        href="https://instagram.com/noa_talel?igshid=MzRlODBiNWFlZA=="
        className="instagram social social-item"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon color="black" icon={faInstagram} size="2x" />
      </a>
      <a
        href="https://www.facebook.com/noa.talel.7/"
        className="facebook social social-item"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon color="black" icon={faFacebook} size="2x" />
      </a>

      <a
        href="https://www.tiktok.com/@noa_talel12"
        className="instagram social social-item"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon color="black" icon={faTiktok} size="2x" />
      </a>
    </div>
  );
}

export default SocialMedia;
