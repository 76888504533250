import React from "react";
import { useState, useEffect } from "react";
function MessageNavbar(props) {
  let stringArray = props.messages;
  const [message, setMessage] = useState(stringArray[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Increment the index
      setCurrentIndex((prevIndex) => (prevIndex + 1) % stringArray.length);
    }, 5000);

    return () => {
      // Clear the timer when the component unmounts or when currentIndex changes
      clearTimeout(timer);
    };
  }, [currentIndex, stringArray.length]);

  useEffect(() => {
    // Update the current string when currentIndex changes
    setMessage(stringArray[currentIndex]);
  }, [currentIndex, stringArray]);

  return <div className="message">{message}</div>;
}

export default MessageNavbar;
