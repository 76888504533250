export const fetchProducts = async () => {
  // Fetch the JSON data from product.json
  try {
    const response = await fetch("/noaWebsite.products.json");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching product data:", error);
  }
};
