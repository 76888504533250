import React, { useEffect, useState } from "react";
import "./pages.css";
import { fetchProducts } from "../Components/fetchProducts";
import OneProduct from "../Components/OneProduct";
import Row from "react-bootstrap/Row";
import InviteBtn from "../Components/ModalBox";
function Collection() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts()
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  return (
    <div className="collection-page">
      <h1 className="title">Products</h1>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
          flexFlow: "column",
        }}
      >
        <p>רוצה להזמין מוצרים אלייך עד הבית?</p>
        <InviteBtn></InviteBtn>
      </div>
      <div className="collection">
        <Row xs={2} md={4} style={{ justifyContent: "start" }}>
          {products.map((product, index) => (
            <div>
              <OneProduct key={index} product={product}></OneProduct>
            </div>
          ))}
        </Row>
      </div>{" "}
    </div>
  );
}

export default Collection;
