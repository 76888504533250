import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Store } from "./Store";
import CartScreen from "../../Pages/Cart";
import Badge from "react-bootstrap/Badge";

export default function CartBtn() {
  const [stateLeft, setStateLeft] = React.useState({
    left: false,
  });
  const { state } = useContext(Store);
  const { cart } = state;

  const toggleDrawer = (left, open) => (event) => {
    if (
      event.type === "keydown" ||
      event.key === "Tab" ||
      (event.key === "Tab" && event.type === "keydown") ||
      event.key === "Shift"
    ) {
      return;
    }

    setStateLeft({ ...stateLeft, [left]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <i
        style={{
          cursor: "pointer",
          marginTop: "5%",
          marginLeft: "5%",
        }}
        onClick={toggleDrawer(anchor, false)}
        class="icon-cart fa-regular fa-x"
      ></i>

      <CartScreen></CartScreen>
    </Box>
  );

  return (
    <div>
      <Button size="2x" onClick={toggleDrawer("left", true)}>
        <i
          class="fa-solid fa-bag-shopping social-item"
          style={{ color: "#2E2C2C", fontSize: "20px" }}
        ></i>
        {cart.cartItems.length > 0 && (
          <Badge pill bg="secondary">
            {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
          </Badge>
        )}{" "}
      </Button>
      <Drawer
        anchor={"left"}
        open={stateLeft["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </div>
  );
}
