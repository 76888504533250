import React from "react";
import Card from "react-bootstrap/Card";
import "react-multi-carousel/lib/styles.css";
import Col from "react-bootstrap/esm/Col";
import "./Components.css";
import AddCart from "./Cart/AddCart";

function OneProduct(props) {
  return (
    <div>
      <Col key={props.index}>
        <Card className="card-products">
          <Card.Body className="card-body">
            <Card.Img
              className="card-img"
              src={props.product.image}
              height={300}
            />
            <div className="details-card">
              <Card.Title className="title-product">
                {props.product.name}
              </Card.Title>
              {/* <Card.Text className="des-product">{props.product.text}</Card.Text> */}
              <Card.Text className="price-product">
                ₪{props.product.price}
              </Card.Text>
              <AddCart product={props.product}></AddCart>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}

export default OneProduct;
