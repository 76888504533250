import React from "react";
import "./Header.css";

function Banner(props) {
  return (
    <div
      className="p-5 text-center bg-image"
      style={{
        backgroundImage: props.url,
        height: 650,
        backgroundPositionY: "center",
        backgroundPositionX: "center",
        backgroundSize: "cover",

        // backgroundRepeat: "no-repeat",
      }}
    >
      <div className="mask">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="text-white title-banner">
            <h1 className="main-title">KEEP YOUR FACE GLOWING</h1>
            <h4 className="mb-3 amit">קליניקה לשיקום וטיפוח העור</h4>
            {/* <a
              className="btn btn-outline-light btn-lg "
              href="/Products"
              role="button"
            >
              לחצו כאן לרכישה
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
