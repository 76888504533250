import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import LinearProgress from "@mui/material/LinearProgress"; // Import LinearProgress from MUI
import "./pages.css";

export default function Gallery() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulate an asynchronous operation (e.g., API call)
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="gallery-page">
      <h1 className="title">Gallery</h1>

      {loading ? (
        // Display a loading bar while data is loading
        <LinearProgress style={{ width: "100%" }} />
      ) : (
        // Display the ImageList when loading is complete
        <ImageList variant="masonry" cols={2} gap={8} className="gallery">
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                className="hover"
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </div>
  );
}

const itemData = [
  {
    img: "https://i.imagesup.co/images2/02851ee24c3d7a8725a20e7a7a0b504a15ac1eeb.png",
    title: "1",
  },
  {
    img: "https://i.imagesup.co/images2/1de33a46b3b497287de85a498142a0c9fd713859.png",
    title: "2",
  },
  {
    img: "https://i.imagesup.co/images2/8b954b3a489725c97045858a01e6887813953c01.png",
    title: "3",
  },
  {
    img: "https://i.imagesup.co/images2/d54394590076a326407298064218a4cfc346f5bc.png",
    title: "4",
  },
  {
    img: "https://i.imagesup.co/images2/79e69596fb2de72a1302c14a6cb0caf42513dc20.jpg",
    title: "5",
  },
  {
    img: "https://i.imagesup.co/images2/42b73a4679d0afd3efe23483f774c34df993a68b.png",
    title: "6",
  },

  {
    img: "https://i.imagesup.co/images2/e59ee830784672195cc61b392afa55519df914dd.jpg",
    title: "",
  },

  {
    img: "https://i.imagesup.co/images2/ac7074c679a89c33a105df84622b31a08a1e17f9.jpg",
    title: "",
  },
  {
    img: "https://i.imagesup.co/images2/f6f372da50d28f84b2d9d9da09069f2efd7dbb62.jpg",
    title: "",
  },
  {
    img: "https://i.imagesup.co/images2/efa7905abd46509f48b960979ef1035fe030c956.jpg",
    title: "1",
  },
  {
    img: "https://i.imagesup.co/images2/c756412d184c989bd79d040c05c42123a03cc4fc.jpg",
    title: "",
  },
  {
    img: "https://i.imagesup.co/images2/1e2c1b9d7441e80716312715c870689f5b357d8f.jpg",
    title: "",
  },
  {
    img: "https://i.imagesup.co/images2/d9c54dd1ffc88c2dab9b7431d125150502b83013.jpg",
    title: "",
  },
  {
    img: "https://i.imagesup.co/images2/cb1778a11f3656df4858f9d892b412ddd50ab425.jpg",
    title: "",
  },
  {
    img: "https://i.imagesup.co/images2/6d0817a203a8b68100a9fe2a7751b0f891531eaa.jpg",
    title: "",
  },
  {
    img: "https://i.imagesup.co/images2/6193ae6f8b876123541bad47ea8818fafb6c6000.jpg",
    title: "",
  },

  {
    img: "https://i.imagesup.co/images2/c1889bb8d92f42c1c86f2703318413955bd8048a.jpg",
    title: "10/",
  },
  {
    img: "https://i.imagesup.co/images2/23603951d1c89dca432a94df9278d4d2705f5f85.jpg",
    title: "11/",
  },
  {
    img: "https://i.imagesup.co/images2/6281f5be51efc853abe8da7b7432fad6383ea0d0.jpg",
    title: "12/",
  },
  {
    img: "https://i.imagesup.co/images2/aa9692d5a3de7854492741324e52e9fdb7d91ce1.jpg",
    title: "13/",
  },
  {
    img: "https://i.imagesup.co/images2/8479a6b5fa915223d1a5d9fb37dfb6ba0dcdccfa.jpg",
    title: "14/",
  },
  {
    img: "https://i.imagesup.co/images2/e4c14a3d5163db497d02f86e2297cf0b62e37dc3.jpg",
    title: "15",
  },
  {
    img: "https://i.imagesup.co/images2/f90fde0370d6ab392078a387183c7661e18be34a.png",
    title: "16",
  },
];
