import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import OneProduct from "./OneProduct";
import "./Components.css";
import { fetchProducts } from "./fetchProducts.js";
import React, { useEffect, useState } from "react";
function CarouselProduct() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts()
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="body2">
      {/* <h3 className="title1">Products</h3> */}

      <Carousel
        className="carousel"
        showDots={true}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-20-px"
        containerClass="carousel-container"
        autoPlay={true}
        rtl={true}
        focusOnSelect={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        autoPlaySpeed={2500}
        infinite={true}
        pauseOnHover={true}
        responsive={responsive}
      >
        {products.map((product, index) => (
          <OneProduct key={index} product={product}></OneProduct>
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselProduct;
