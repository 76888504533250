import React, { useContext } from "react";
import { Store } from "../Components/Cart/Store";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./pages.css";
import InviteBtn from "../Components/ModalBox";
export default function CartScreen() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const updateCartHandler = async (item, quantity) => {
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...item, quantity },
    });
  };

  const removeItemHandler = (item) => {
    ctxDispatch({ type: "CART_REMOVE_ITEM", payload: item });
  };

  return (
    <div dir="rtl" className="cart-page">
      <h1 className="title-cart ">עגלת הקניות שלך</h1>
      <p className="number-details">*המשך ההזמנה מתבצעת בטלפון</p>
      {cartItems.length === 0 ? (
        <div className="number-details">העגלה שלך כרגע ריקה</div>
      ) : (
        <ListGroup>
          <div className="body-cart">
            {cartItems.map((item) => (
              <ListGroup.Item key={item._id}>
                <Row className="align-items-center">
                  <Col xs={5} md={4}>
                    <img
                      src={item.image}
                      alt={item.name}
                      className="image-cart img-fluid rounded img-thumbnail"
                    ></img>
                  </Col>
                  <Col xs={5} md={5}>
                    <Row>
                      <div className="name-cart">{item.name}</div>
                      <div className="price-cart"> {item.price} ₪</div>
                    </Row>
                    <Row xs={6} md={6}>
                      <Col xs={6} md={3} className="quantity-cart">
                        <Button
                          onClick={() =>
                            updateCartHandler(item, item.quantity - 1)
                          }
                          variant="light"
                          className="quantity"
                          disabled={item.quantity === 1}
                        >
                          <i class="fa fa-minus"></i>{" "}
                        </Button>
                        <span>{item.quantity}</span>
                        <Button
                          variant="light"
                          className="quantity"
                          onClick={() =>
                            updateCartHandler(item, item.quantity + 1)
                          }
                          disabled={item.quantity === item.countInStock}
                        >
                          <i class="fa-regular fa-plus"></i>{" "}
                        </Button>
                      </Col>
                      <Col md={4} className="remove-cart">
                        <a
                          onClick={() => removeItemHandler(item)}
                          variant="light"
                        >
                          להסרה
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </ListGroup.Item>
            ))}
            <p className="number-details">
              {cartItems.reduce((a, c) => a + c.quantity, 0)} פריטים
            </p>
          </div>

          <ListGroup variant="flush">
            <ListGroup.Item className="sum-div">
              <div className="sum-cart">
                <p>סכום</p>
                <p className="number-sum">
                  {" "}
                  {cartItems.reduce(
                    (a, c) => a + Math.round(c.price) * c.quantity,
                    0
                  )}
                  ₪
                </p>
              </div>
              <Row>
                <div className="btn-cart d-grid">
                  <InviteBtn
                    type="button"
                    variant="primary"
                    disabled={cartItems.length === 0}
                    style={{ width: "80%" }}
                  ></InviteBtn>
                </div>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </ListGroup>
      )}
    </div>
  );
}
