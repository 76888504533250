import React, { useRef, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./Components.css";
import emailjs from "@emailjs/browser";
import { Store } from "./Cart/Store";
const emails = ["username@gmail.com", "user02@gmail.com"];

function ModalBox(props) {
  const { onClose, selectedValue, open } = props;
  const form = useRef();
  const [productCart, setProductCart] = useState("");
  const [messageSent, SetMessageSent] = useState("");

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  useEffect(() => {
    SetMessageSent("");
    const formattedStrings = ["אשמח ליצור קשר להזמנת המוצרים הבאים: \n"];
    if (cartItems.length > 0) {
      for (const obj of cartItems) {
        const formattedString = `שם מוצר: ${obj.name}\nכמות: ${obj.quantity}\n--------\n`;
        formattedStrings.push(formattedString);
      }
      formattedStrings.push(
        `סה"כ פריטים: ${cartItems.reduce((a, c) => a + c.quantity, 0)}\n`
      );
      formattedStrings.push(
        `סכום: ${cartItems.reduce(
          (a, c) => a + Math.round(c.price) * c.quantity,
          0
        )} ₪ \n`
      );

      const finalString = formattedStrings.join("\n");

      setProductCart(finalString);
    } else {
      setProductCart("");
    }
    console.log(productCart);
  }, [cartItems]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const removeItemHandler = (item) => {
    cartItems.map((item) =>
      ctxDispatch({ type: "CART_REMOVE_ITEM", payload: item })
    );
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current.user_name);
    emailjs
      .sendForm(
        "service_2mbye4l",
        "template_inkcory",
        form.current,
        "3nh_vmEtIxbIRJhK2"
      )
      .then(
        (result) => {
          console.log(result.text);
          SetMessageSent("ההודעה נשלחה בהצלחה, ניצור איתך קשר בהקדם.");
          setTimeout(removeItemHandler, 1500);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Dialog dir="rtl" onClose={handleClose} open={open}>
      <i
        style={{
          cursor: "pointer",
          marginRight: "5%",
          marginTop: "5%",
        }}
        onClick={handleClose}
        class="icon-cart fa-regular fa-x"
      ></i>
      <DialogTitle>
        <h3> יצירת קשר להזמנה עד הבית</h3>
        מלא/י את הפרטים הבאים ואצור איתך קשר בהקדם לביצוע ההזמנה עם הפריטים
        שבחרת.
      </DialogTitle>
      <Form className="form-contact" ref={form} onSubmit={sendEmail}>
        <Form.Control type="hidden" name="type_message" value="הזמנה" />
        <p style={{ color: "red" }}>{messageSent}</p>

        <Row className="row-contact">
          <Col>
            <Form.Control
              inputMode="text"
              type="text"
              placeholder="שם מלא"
              name="user_name"
              required={true}
            />
          </Col>
          <Col>
            <Form.Control
              type="number"
              inputMode="tel"
              name="user_phone"
              placeholder=" טלפון"
              required={true}
            />
          </Col>
        </Row>
        <Row className="row-contact">
          <Col>
            <Form.Control
              inputMode="email"
              placeholder="דואר"
              type="email"
              name="user_email"
              required={true}
            />
          </Col>
        </Row>
        <Row className="row-contact">
          <Col>
            <Form.Control
              type="hidden"
              // as="textarea"
              name="message"
              rows={8}
              placeholder="תוכן ההודעה/פריטים להזמנה"
              defaultValue={productCart}
              required={true}
            />
          </Col>
        </Row>
        <p>* זוהי בקשה ליצירת קשר להזמנה טלפונית, אין חיוב בשלב זה.</p>
        <Button type="submit" value="Send" variant="outline-dark">
          שלח
        </Button>
      </Form>
    </Dialog>
  );
}

ModalBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function InviteBtn() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button variant="outline-dark" onClick={handleClickOpen}>
        יצירת קשר להזמנה
      </Button>
      <ModalBox
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
