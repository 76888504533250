import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Components/Header/Header";
import Collection from "./Pages/Collection";
import About from "./Pages/About";
import "./App.css";
import Footer from "./Components/Footer";
import Contact from "./Pages/Contact";
import Fab from "@mui/material/Fab";
import Gallery from "./Pages/Gallery";
import Box from "@mui/material/Box";

let router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/Products", element: <Collection /> },
  { path: "/About", element: <About /> },
  {
    path: "/Contact",
    element: <Contact />,
  },
  {
    path: "/Gallery",
    element: <Gallery />,
  },
]);

function App() {
  return (
    <div dir="rtl">
      <Box
        sx={{
          "& > :not(style)": {
            position: "fixed",
            bottom: 16,
            right: 16,
            background: "white",
            width: 50,
            height: 50,
          },
        }}
      >
        <Fab color="primary" aria-label="add">
          <a
            href="https://api.whatsapp.com/message/HCIHIGRHH5J3B1?autoload=1&app_absent=0"
            className="whatsapp social social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://i.imagesup.co/images2/4fafd84c293b35a8b571a0a44098f53606a42816.png"
              width={50}
              alt="icon"
            ></img>
          </a>
        </Fab>
      </Box>
      <Header></Header>
      <RouterProvider router={router}></RouterProvider>
      <Footer></Footer>
    </div>
  );
}

export default App;
