import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Header.css";
import logo from "../../Assest/logo/logo-noa.png";
import CartBtn from "../Cart/boxCart";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function NavbarMenu() {
  return (
    <Navbar
      bg="white"
      variant="light"
      expand="lg"
      sticky="top"
      data-screen="sf-header__mobile"
      data-transparent="false"
      className="flex lg:hidden sf-header__mobile container-fluid bg-white items-center  your-custom-navbar-class "
    >
      <Navbar.Toggle
        type="button"
        aria-expanded="false"
        aria-label="Toggle navigation"
        className="toggle"
        aria-controls="basic-navbar-nav"
      />
      <Navbar.Brand
        className="sf-logo sf-logo--mobile px-4 w-1/2 justify-center has-logo-img items-center
"
        href="/"
      >
        <div class="sf-image sf-logo-mobile items-center">
          <img
            src={logo}
            alt="Logo"
            width="40"
            height="40"
            style={{
              marginLeft: "3%",
              alignSelf: "center",
              marginTop: -7,
              justifyContent: "center",
            }}
            className="inline-block block py-2.5 logo-img relative"
          />
        </div>
      </Navbar.Brand>
      {window.innerWidth < 768 && <CartBtn />}
      <Navbar.Collapse id="basic-navbar-nav">
        <Container className="card-body">
          <Nav className="card-body">
            <Nav.Link href="/">דף בית</Nav.Link>
            <Nav.Link href="/About">אודות</Nav.Link>
            <Nav.Link href="/Products">מוצרים </Nav.Link>
            <Nav.Link href="/Gallery">גלריה</Nav.Link>

            <Nav.Link href="/Contact">צור קשר</Nav.Link>
          </Nav>
        </Container>

        <a
          href="https://api.whatsapp.com/message/HCIHIGRHH5J3B1?autoload=1&app_absent=0"
          className="whatsapp social social-item"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon color="black" icon={faWhatsapp} size="2x" />
        </a>

        <a
          href="https://instagram.com/noa_talel?igshid=MzRlODBiNWFlZA=="
          className="instagram social social-item"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon color="black" icon={faInstagram} size="2x" />
        </a>
      </Navbar.Collapse>
      {window.innerWidth >= 768 && <CartBtn />}
    </Navbar>
  );
}

export default NavbarMenu;
